import vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

const store = new vuex.Store({
  state: {
    isLogin: false, //ログイン状態
    userId: '', //トークン
    employeeNumber: ''
  },
  mutations: {
    auth(state, user){
      state.isLogin = true;
      state.userId = user;
    },
    setEmployeeNumber(state, employeeNumber) {
      state.employeeNumber = employeeNumber;
    }
  },
  actions: {
    fetch(context, payload) {
      context.commit('auth', payload.user);
      context.commit('setEmployeeNumber', payload.employeeNumber);
    },
    setAuthToken: function(commit, value) {
      commit('setAuthToken', value)
    },
    setIsLogin: function(commit, value) {
      commit('setIsLogin', value)
    }
  },
  getters:{
    getEmployeeNumber(state) {
      return state.employeeNumber;
    },
    getAuthToken(state){
      return state.authToken
    },
    getIsLogin(state){
      return state.isLogin
    }
  },
  
  plugins: [createPersistedState(
    { 
      storage: window.sessionStorage
    }
  )]
});

export default store;