<template>
  <form class="WorkHours">
    <h2>勤務時間登録</h2>
    
    <!-- No.2 日付 -->
    <div class="form">
      <span>日付：</span>
      <input type="date" v-model="workDay" placeholder="" required>
    </div>
    
    <!-- No.3 出社時間 -->
    <div class="form">
      <span>出社時間：</span>
      <input type="time" v-model="workStart" placeholder="" required>
    </div>
    
    <!-- No.4 退社時間 -->
    <div class="form">
      <span>退社時間：</span>
      <input type="time" v-model="workEnd" placeholder="" required>
    </div>
    
    <!-- No.5 休憩開始 -->
    <div class="form">
      <span>休憩開始：</span>
      <input type="time" v-model="breakStart" placeholder="" required>
    </div>
    
    <!-- No.6 休憩終了 -->
    <div class="form">
      <span>休憩終了：</span>
      <input type="time" v-model="breakEnd" placeholder="">
    </div>
    
    <!-- No.7 連絡事項 -->
    <div class="form">
      <span>連絡事項：</span>
      <input id="type" type="text" list="typelist" v-model="contactInfo" placeholder="選択して下さい">
      <datalist id="typelist">
        <option>有休</option>
        <option>半休</option>
        <option>私事</option>
        <option>その他</option>
      </datalist>
    </div>
    <div class="btn">
      <!-- No.8 戻る -->
      <a class="link" @click="goBack">戻る</a>
      <!-- No.9 登録 -->
      <button type="submit" @click="post">登録</button>
      <br/><p>{{ msg }}</p>
    </div>
  </form>
</template>

<script setup>
// 使用するモジュールの読込
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';

// 変数の宣言
const router = useRouter();
const store = useStore();

const msg = ref('');
const workDay = ref('');
const workStart = ref('');
const workEnd = ref('');
const breakStart = ref('');
const breakEnd = ref('');
const contactInfo = ref('');

// 関数の宣言
/**
 * 入力された勤怠情報を送信し、ログイン可否を判定します。
 */
async function post() {
  const apiURL = `${process.env.VUE_APP_API_URL}/attendance`;
  const attendanceData = {
    employeeNumber: store.state.employeeNumber,
    workDay: workDay.value,
    workStart: workStart.value,
    workEnd: workEnd.value,
    breakStart: breakStart.value,
    breakEnd: breakEnd.value,
    contactInfo: contactInfo.value,
  };

  try {
    const result = await axios.post(apiURL, attendanceData);
    // リクエスト成功時の処理
    console.log(result.data);

    switch (result.data.code) {
      case 'S0001':
        msg.value = "登録が成功しました";
        break;
      case 'ERR01':
        msg.value = '登録失敗しました';
        break;
    }
    alert(msg.value);
  } catch (error) {
    // リクエスト失敗時の処理
    console.error(error);
    window.alert('エラーが発生しました。')
  }
}

/**
 * 戻るボタン押下時、勤怠一覧画面へ遷移します。
 */
function goBack() {
  router.push({path: '/AttendanceList'});
}

</script>

<!-- css -->
<style scoped>
.WorkHours {
  margin-top: 50px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;

}

h2 {
  font-size: 30px;
  line-height: 80pt;
}

.form input {
  width: 200px;
  vertical-align: top;
}

span {
  display: inline-block;
  width: 150px;
  text-align: justify;
  height: 50px;
  overflow: hidden;
}

span:after {
  content: '';
  display: inline-block;
  width: 100%;
}

.btn {
  text-align: center;
}
.btn > * {
  margin: 0 1rem;
}

.link {
  color: #007dff;
  border-bottom: solid 1px #007dff;
  cursor: pointer;
}
</style>