<template>
  <div class="container">
    <div class="come">
      <!-- No.2 ログアウト -->
      <button class="back" @click="logout">ログアウト</button>
      <!-- No.3 ダウンロード -->
      <button class="ch" @click="downloadCSV">ダウンロード</button>
    </div>

    <!-- No.5 勤怠情報 -->
    <div class="attendance">
      <h2>勤怠一覧</h2>
      <table>
        <thead class="sample">
          <tr>
            <th>日付</th>
            <th>出社時間</th>
            <th>退社時間</th>
            <th>休憩開始</th>
            <th>休憩終了</th>
            <th>連絡事項</th>
          </tr>
        </thead>
        <tbody class="days">
          <tr v-for="attendance in attendanceList" :key="attendance.id">
            <td>{{ attendance.workDay }}</td> <!-- No.5-1 日付 -->
            <td>{{ attendance.workStart }}</td> <!-- No.5-2 出社時間 -->
            <td>{{ attendance.workEnd }}</td> <!-- No.5-3 退社時間 -->
            <td>{{ attendance.breakStart }}</td> <!-- No.5-4 休憩開始 -->
            <td>{{ attendance.breakEnd }}</td> <!-- No.5-5 休憩終了 -->
            <td>{{ attendance.contactInfo }}</td> <!-- No.5-6 連絡事項 -->
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <!-- No.4 登録 -->
      <div class="subr">
        <button class="ch" @click="recordButtonClicked">登録</button>
      </div>
    </div>
  </div>
</template>

<script setup>
// 使用するモジュールの読込
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';

// 変数の宣言
const store = useStore();
const router = useRouter();

const attendanceList = ref([]);
const employeeNumber = ref('');
const msg = ref('');

// 関数の宣言
/**
 * APIへ接続し、社員番号に紐づく勤怠情報を取得します。
 */
async function fetchAttendanceList() {
  try {
    employeeNumber.value = store.getters.getEmployeeNumber; // 社員番号を代入
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/attendances/employeeNumber/${employeeNumber.value}`);
    console.log(response);
    switch (response.data.code) {
      case 'S0001': {
        attendanceList.value = response.data.data; // バックエンドから取得したデータを配列に格納
        break
      }
      case 'ERR01': {
        msg.value = '登録失敗しました';
      }
    }
  } catch (error) {
    console.error(error);
    window.alert('勤怠一覧の取得に失敗しました');
  }
}

/**
 * 勤怠情報をCSVとしてダウンロードします。
 */
async function downloadCSV() {
  try {
    // CSVデータをダウンロードするためのHTTP GETリクエストを送信
    const response = await axios.get( `${process.env.VUE_APP_API_URL}/download-csv/${employeeNumber.value}`, {
      responseType: 'blob' // レスポンスをBlob形式で受け取る
    });
    console.log(response);
    // レスポンスからBlobオブジェクトを作成
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Blobオブジェクトからダウンロード用のURLを生成
    const url = window.URL.createObjectURL(blob);

    // ダウンロードリンクを作成し、ファイル名を設定
    const a = document.createElement('a');
    a.href = url;
    a.download = `attendance_${employeeNumber.value}.csv`;

    // ダウンロードリンクをクリックしてファイルをダウンロード
    a.click();

    // 使用したURLを解放
    window.URL.revokeObjectURL(url);
  } catch (error) {
    //エラーハンドリング：エラーメッセージを出力し、アラートを表示
    console.error(error);
    window.alert('CSVファイルのダウンロードに失敗しました');
  }
}

/**
 * 登録ボタン押下時、勤務時間登録画面へ遷移します。
 */
function recordButtonClicked() {
  router.push({path: '/AttendanceRecord'});
}

/**
 * ログアウトボタン押下時、ログイン画面へ遷移します。
 */
function logout() {
  router.push({path: '/'});
}

/**
 * このページがロードされた後、勤怠情報の取得を行います。
 */
onMounted(async () => {
  await fetchAttendanceList();
});

</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.attendance {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.sample {
  margin-top: 20px;
  text-decoration: underline;
}

.days {
  margin-top: 5px;
}

.come {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back {
  background-color: #007dff;
  color: #fff;
  text-align: center;
  margin-bottom: 5rem;
  width: auto;
}

.ch {
  background-color: #007dff;
  color: #fff;
  text-align: center;
}

.subr {
  width: 5rem;
  align-items: center;
}

.subl {
  width: 5rem;
  align-items: center;
}
</style>
