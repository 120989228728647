<template>
  <div class="login">
    <h2>勤怠システム</h2>
    
    <div>
      <div class="container" style="width: 500px;">
        <!-- No.1 社員番号 -->
        <div class="row align-items-center tel">
          <input type="tel" class="id" v-model="id" maxlength="4" placeholder="社員番号" required>
        </div>

        <!-- No.2 パスワード -->
        <div class="row align-items-center text" >
          <input type="password"  class="password" v-model="password" maxlength="25" placeholder="パスワード" 
          prepend-icon="mdi-lock" v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
          required>
        </div>

        <!-- No.3 メッセージ -->
        {{ msg }}
        <div class="row align-items-center button">
          <!-- No.4 ログイン -->
          <button class="btn btn-info Login" @click="post">
            ログイン
          </button>
        </div>
        <div class="comment">
          <!-- No.5 SignUp -->
          <p class="register">
            アカウントをお持ちでない方は <a class="link" @click="signUp">SignUp</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 使用するモジュールの読込
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';

// 変数の宣言
const store = useStore();
const router = useRouter();

const showPassword = ref(false);
const msg = ref('');
const id = ref('');
const password = ref('');

// 関数の宣言
/**
 * 入力された社員番号とパスワードを送信し、ログイン可否を判定します。
 */
async function post() {
  const data = { 
    employeeNumber : id.value, 
    password : password.value
  };
  try { // 例外が発生するかどうか確認する処理
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/login`, data); //ここでバックエンド側にポスト
    console.log(response);
    const respData = response.data;
    switch(respData.code){
      case 'S0001':
        store.dispatch('fetch', { user: respData.authId, employeeNumber: id.value });
        router.push({path: '/AttendanceList'}); // 認証成功時、vue-routerを用いてログイン画面へ遷移
        break;
      case 'ERR01':
      default:
        msg.value ='社員番号又はパスワードが間違っています。'
    }
  } catch (error) { // 動作に異常があった場合の処理
    console.error(error);
    window.alert('エラーが発生しました');
  }
}

/**
 * SignUp押下時に、アカウント登録画面へ遷移します。
 */
function signUp() {
  router.push({path: '/RegisterUser'});
}
</script>

<style scoped>
.login {
  margin-top: 100px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
}
.id{
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}
.password{
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}
.tel{
  padding: 10px;
}
.text{
  padding: 10px;
}
.button{
  padding: 20px;
  margin: auto;
}
.btn{
  background-color: #007dff;
  color: #fff;
  text-align: center;
  width: 50%;
  margin: auto;
  align-items: center;
}
.comment{
  padding: 10px;
}
.link {
  color: #007dff;
  border-bottom: solid 1px #007dff;
  cursor: pointer;
}
</style>