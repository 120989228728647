<template>
  <div class="register-container">
    <h2>アカウント登録</h2>
    <div>
      <form @submit.prevent="submitForm" class="register-form">
        <!-- No.1 氏名 -->
        <div class="form-item">
          <label for="name"></label>
          <input type="text" class="name" id="name" v-model="name" maxlength="20" placeholder="氏名" required>
        </div>

        <!-- No.2 社員番号 -->
        <div class="form-item">
          <label for="employee-number"></label>
          <input type="tel" class="employeeNumber" id="employee-number" v-model="employee_number" maxlength="4"
            placeholder="社員番号" required>
        </div>

        <!-- No.3 パスワード -->
        <div class="form-item">
          <label for="password"></label>
          <input type="password" class="password" id="password" v-model="password" maxlength="25" placeholder="パスワード"
            required>
        </div>

        <!-- No.4 パスワード確認 -->
        <div class="form-item">
          <label for="checkPass"></label>
          <input type="password" class="checkPass" id="confirm-password" v-model="checkPass" maxlength="25"
            placeholder="パスワード確認" required>
        </div>

        <!-- No.5 Login -->
        <p>
          アカウントをお持ちの方は <a class="link" @click="login">Login</a>
        </p>
        
        <!-- No.6 メッセージ -->
        <p>
          {{ msg }}
        </p>

        <!-- No.7 登録 -->
        <div class="form-item">
          <button type="submit" class="btn" @click="sendData">登録</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
// 使用するモジュールの読込
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

// 変数の宣言
const router = useRouter();

const name = ref('');
const password = ref('');
const checkPass = ref('');
const employee_number = ref('');
const msg = ref('');

// 関数の宣言
/**
 * 入力された情報をバックエンドへ送信し、アカウント登録を行います。
 */
async function sendData() {
  if (!name.value || !password.value || !employee_number.value) {
    window.alert('すべて入力してください');
    return;
  }
  if (password.value !== checkPass.value) {
    window.alert('パスワードが一致しません');
    return;
  }
  const apiURL = `${process.env.VUE_APP_API_URL}/employee`;
  const postData = {
    name: name.value,
    password: password.value,
    employeeNumber: employee_number.value
  };
  try { // 例外が発生するかどうか確認する処理
    const response = await axios.post(apiURL, postData);
    console.log(response.data);
    
    switch (response.data.code) {
      case 'S0001':
        router.push({path: '/'}); // 登録成功時、vue-routerを用いてログイン画面へ遷移
        break;
      case 'ERR01':
        msg.value = '登録失敗しました';
    }
  } catch (axiosError) { // 動作に異常があった場合の処理
    if (axiosError.response && axiosError.response.data) {
      window.alert(axiosError.response.data.message);
    } else {
      window.alert('エラーが発生しました');
    }
  }
}

/**
 * login押下時、ログイン画面へ遷移します。
 */
function login() {
  router.push({path: '/'});
}
</script>

<!-- css -->
<style scoped>
.register-container {
  margin-top: 100px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
}

.register-form {
  width: 55%;
  padding: 0.5rem;
  margin: auto;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  background-color: #007dff;
  color: #fff;
  text-align: center;
  width: 50%;
  margin: auto;
  align-items: center;
}

.form-item {
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}

.password {
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}

.checkPass {
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}

.name {
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}

.employeeNumber {
  width: 70%;
  padding: 0.5rem;
  margin: auto;
}
.link {
  color: #007dff;
  border-bottom: solid 1px #007dff;
  cursor: pointer;
}
</style>