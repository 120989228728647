import { createRouter, createWebHistory } from 'vue-router'
import LoginUser from '@/components/LoginUser.vue'
import RegisterUser from '@/components/RegisterUser.vue'
import AttendanceList from '@/components/AttendanceList.vue'
import AttendanceRecord from '@/components/AttendanceRecord.vue'

const routes = [
  {
    path: '/',
    name: 'LoginUser',
    component: LoginUser
  },
  {
    path: '/RegisterUser',
    name: 'RegisterUser',
    component: RegisterUser
  },
  {
    path: '/AttendanceList',
    name: 'AttendanceList',
    component: AttendanceList
  },
  {
    path: '/AttendanceRecord',
    name: 'AttendanceRecord',
    component: AttendanceRecord
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
